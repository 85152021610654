.list {
    text-align: left;
    padding: 0 10%;
}

.item {
    padding: 15px 0;
}

.time {
    margin-top: 8px;
    font-size: 0.8em;
    letter-spacing: 3px;
}